exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-index-js": () => import("./../../../src/pages/business/index.js" /* webpackChunkName: "component---src-pages-business-index-js" */),
  "component---src-pages-business-onboarding-index-js": () => import("./../../../src/pages/business/onboarding/index.js" /* webpackChunkName: "component---src-pages-business-onboarding-index-js" */),
  "component---src-pages-business-products-and-solutions-2-moorch-business-js": () => import("./../../../src/pages/business/products-and-solutions/2-moorch-business.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-2-moorch-business-js" */),
  "component---src-pages-business-products-and-solutions-association-bundle-apply-js": () => import("./../../../src/pages/business/products-and-solutions/association-bundle/apply.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-association-bundle-apply-js" */),
  "component---src-pages-business-products-and-solutions-association-bundle-index-js": () => import("./../../../src/pages/business/products-and-solutions/association-bundle/index.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-association-bundle-index-js" */),
  "component---src-pages-business-products-and-solutions-bulk-sms-jsx": () => import("./../../../src/pages/business/products-and-solutions/bulk-sms.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-bulk-sms-jsx" */),
  "component---src-pages-business-products-and-solutions-business-broadband-js": () => import("./../../../src/pages/business/products-and-solutions/business-broadband.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-business-broadband-js" */),
  "component---src-pages-business-products-and-solutions-enterprise-fixed-voice-jsx": () => import("./../../../src/pages/business/products-and-solutions/enterprise-fixed-voice.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-enterprise-fixed-voice-jsx" */),
  "component---src-pages-business-products-and-solutions-index-jsx": () => import("./../../../src/pages/business/products-and-solutions/index.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-index-jsx" */),
  "component---src-pages-business-products-and-solutions-iot-jsx": () => import("./../../../src/pages/business/products-and-solutions/iot.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-iot-jsx" */),
  "component---src-pages-business-products-and-solutions-linkify-index-js": () => import("./../../../src/pages/business/products-and-solutions/linkify/index.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-linkify-index-js" */),
  "component---src-pages-business-products-and-solutions-linkify-receipt-jsx": () => import("./../../../src/pages/business/products-and-solutions/linkify/receipt.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-linkify-receipt-jsx" */),
  "component---src-pages-business-products-and-solutions-one-business-js": () => import("./../../../src/pages/business/products-and-solutions/one-business.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-one-business-js" */),
  "component---src-pages-business-products-and-solutions-red-trader-js": () => import("./../../../src/pages/business/products-and-solutions/red-trader.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-red-trader-js" */),
  "component---src-pages-business-products-and-solutions-speedlink-jsx": () => import("./../../../src/pages/business/products-and-solutions/speedlink.jsx" /* webpackChunkName: "component---src-pages-business-products-and-solutions-speedlink-jsx" */),
  "component---src-pages-business-products-and-solutions-your-business-online-index-js": () => import("./../../../src/pages/business/products-and-solutions/your-business-online/index.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-your-business-online-index-js" */),
  "component---src-pages-business-products-and-solutions-your-business-online-register-js": () => import("./../../../src/pages/business/products-and-solutions/your-business-online/register.js" /* webpackChunkName: "component---src-pages-business-products-and-solutions-your-business-online-register-js" */),
  "component---src-pages-business-sme-connect-hustles-jsx": () => import("./../../../src/pages/business/_sme/connect-hustles.jsx" /* webpackChunkName: "component---src-pages-business-sme-connect-hustles-jsx" */),
  "component---src-pages-business-sme-empire-she-built-jsx": () => import("./../../../src/pages/business/_sme/empire-she-built.jsx" /* webpackChunkName: "component---src-pages-business-sme-empire-she-built-jsx" */),
  "component---src-pages-business-sme-index-jsx": () => import("./../../../src/pages/business/_sme/index.jsx" /* webpackChunkName: "component---src-pages-business-sme-index-jsx" */),
  "component---src-pages-business-sme-red-loyalty-jsx": () => import("./../../../src/pages/business/_sme/red-loyalty.jsx" /* webpackChunkName: "component---src-pages-business-sme-red-loyalty-jsx" */),
  "component---src-pages-business-sme-rewards-and-discounts-jsx": () => import("./../../../src/pages/business/_sme/rewards-and-discounts.jsx" /* webpackChunkName: "component---src-pages-business-sme-rewards-and-discounts-jsx" */),
  "component---src-pages-business-wholesale-contact-us-jsx": () => import("./../../../src/pages/business/wholesale/contact-us.jsx" /* webpackChunkName: "component---src-pages-business-wholesale-contact-us-jsx" */),
  "component---src-pages-business-wholesale-cross-border-connectivity-js": () => import("./../../../src/pages/business/wholesale/cross-border-connectivity.js" /* webpackChunkName: "component---src-pages-business-wholesale-cross-border-connectivity-js" */),
  "component---src-pages-business-wholesale-dedicated-internet-js": () => import("./../../../src/pages/business/wholesale/dedicated-internet.js" /* webpackChunkName: "component---src-pages-business-wholesale-dedicated-internet-js" */),
  "component---src-pages-business-wholesale-index-js": () => import("./../../../src/pages/business/wholesale/index.js" /* webpackChunkName: "component---src-pages-business-wholesale-index-js" */),
  "component---src-pages-business-wholesale-iplc-js": () => import("./../../../src/pages/business/wholesale/iplc.js" /* webpackChunkName: "component---src-pages-business-wholesale-iplc-js" */),
  "component---src-pages-business-wholesale-wide-area-network-js": () => import("./../../../src/pages/business/wholesale/wide-area-network.js" /* webpackChunkName: "component---src-pages-business-wholesale-wide-area-network-js" */),
  "component---src-pages-business-wib-about-js": () => import("./../../../src/pages/business/wib/about.js" /* webpackChunkName: "component---src-pages-business-wib-about-js" */),
  "component---src-pages-business-wib-digital-market-place-jsx": () => import("./../../../src/pages/business/wib/digital-market-place.jsx" /* webpackChunkName: "component---src-pages-business-wib-digital-market-place-jsx" */),
  "component---src-pages-business-wib-digital-tools-jsx": () => import("./../../../src/pages/business/wib/digital-tools.jsx" /* webpackChunkName: "component---src-pages-business-wib-digital-tools-jsx" */),
  "component---src-pages-business-wib-events-js": () => import("./../../../src/pages/business/wib/events.js" /* webpackChunkName: "component---src-pages-business-wib-events-js" */),
  "component---src-pages-business-wib-index-jsx": () => import("./../../../src/pages/business/wib/index.jsx" /* webpackChunkName: "component---src-pages-business-wib-index-jsx" */),
  "component---src-pages-business-wib-insurance-js": () => import("./../../../src/pages/business/wib/insurance.js" /* webpackChunkName: "component---src-pages-business-wib-insurance-js" */),
  "component---src-pages-business-wib-loans-js": () => import("./../../../src/pages/business/wib/loans.js" /* webpackChunkName: "component---src-pages-business-wib-loans-js" */),
  "component---src-pages-business-wib-market-place-jsx": () => import("./../../../src/pages/business/wib/market-place.jsx" /* webpackChunkName: "component---src-pages-business-wib-market-place-jsx" */),
  "component---src-pages-business-wib-power-lunch-js": () => import("./../../../src/pages/business/wib/power-lunch.js" /* webpackChunkName: "component---src-pages-business-wib-power-lunch-js" */),
  "component---src-pages-business-wib-red-points-js": () => import("./../../../src/pages/business/wib/red-points.js" /* webpackChunkName: "component---src-pages-business-wib-red-points-js" */),
  "component---src-pages-business-wib-registration-js": () => import("./../../../src/pages/business/wib/registration.js" /* webpackChunkName: "component---src-pages-business-wib-registration-js" */),
  "component---src-pages-business-wib-she-means-business-js": () => import("./../../../src/pages/business/wib/she-means-business.js" /* webpackChunkName: "component---src-pages-business-wib-she-means-business-js" */),
  "component---src-pages-business-wib-she-speaks-js": () => import("./../../../src/pages/business/wib/she-speaks.js" /* webpackChunkName: "component---src-pages-business-wib-she-speaks-js" */),
  "component---src-pages-business-wib-webinar-js": () => import("./../../../src/pages/business/wib/webinar.js" /* webpackChunkName: "component---src-pages-business-wib-webinar-js" */),
  "component---src-pages-components-gallery-js": () => import("./../../../src/pages/components-gallery.js" /* webpackChunkName: "component---src-pages-components-gallery-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookie-policy-jsx": () => import("./../../../src/pages/cookie-policy.jsx" /* webpackChunkName: "component---src-pages-cookie-policy-jsx" */),
  "component---src-pages-enterprise-account-balance-jsx": () => import("./../../../src/pages/enterprise/account-balance.jsx" /* webpackChunkName: "component---src-pages-enterprise-account-balance-jsx" */),
  "component---src-pages-enterprise-account-statement-jsx": () => import("./../../../src/pages/enterprise/account-statement.jsx" /* webpackChunkName: "component---src-pages-enterprise-account-statement-jsx" */),
  "component---src-pages-enterprise-index-jsx": () => import("./../../../src/pages/enterprise/index.jsx" /* webpackChunkName: "component---src-pages-enterprise-index-jsx" */),
  "component---src-pages-enterprise-payment-history-jsx": () => import("./../../../src/pages/enterprise/payment-history.jsx" /* webpackChunkName: "component---src-pages-enterprise-payment-history-jsx" */),
  "component---src-pages-enterprise-query-bill-jsx": () => import("./../../../src/pages/enterprise/query-bill.jsx" /* webpackChunkName: "component---src-pages-enterprise-query-bill-jsx" */),
  "component---src-pages-explore-telecel-careers-career-opportunities-js": () => import("./../../../src/pages/explore-telecel/careers/career-opportunities.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-career-opportunities-js" */),
  "component---src-pages-explore-telecel-careers-code-like-a-girl-js": () => import("./../../../src/pages/explore-telecel/careers/code-like-a-girl.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-code-like-a-girl-js" */),
  "component---src-pages-explore-telecel-careers-fessp-js": () => import("./../../../src/pages/explore-telecel/careers/fessp.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-fessp-js" */),
  "component---src-pages-explore-telecel-careers-index-js": () => import("./../../../src/pages/explore-telecel/careers/index.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-index-js" */),
  "component---src-pages-explore-telecel-careers-nextgen-js": () => import("./../../../src/pages/explore-telecel/careers/nextgen.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-nextgen-js" */),
  "component---src-pages-explore-telecel-careers-reconnect-js": () => import("./../../../src/pages/explore-telecel/careers/reconnect.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-reconnect-js" */),
  "component---src-pages-explore-telecel-careers-youth-programs-js": () => import("./../../../src/pages/explore-telecel/careers/youth-programs.js" /* webpackChunkName: "component---src-pages-explore-telecel-careers-youth-programs-js" */),
  "component---src-pages-explore-telecel-executive-team-js": () => import("./../../../src/pages/explore-telecel/executive-team.js" /* webpackChunkName: "component---src-pages-explore-telecel-executive-team-js" */),
  "component---src-pages-explore-telecel-foundation-about-jsx": () => import("./../../../src/pages/explore-telecel/foundation/about.jsx" /* webpackChunkName: "component---src-pages-explore-telecel-foundation-about-jsx" */),
  "component---src-pages-explore-telecel-foundation-board-of-directors-js": () => import("./../../../src/pages/explore-telecel/foundation/board-of-directors.js" /* webpackChunkName: "component---src-pages-explore-telecel-foundation-board-of-directors-js" */),
  "component---src-pages-explore-telecel-foundation-index-jsx": () => import("./../../../src/pages/explore-telecel/foundation/index.jsx" /* webpackChunkName: "component---src-pages-explore-telecel-foundation-index-jsx" */),
  "component---src-pages-explore-telecel-index-js": () => import("./../../../src/pages/explore-telecel/index.js" /* webpackChunkName: "component---src-pages-explore-telecel-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pay-index-js": () => import("./../../../src/pages/pay/index.js" /* webpackChunkName: "component---src-pages-pay-index-js" */),
  "component---src-pages-pay-receipt-js": () => import("./../../../src/pages/pay/receipt.js" /* webpackChunkName: "component---src-pages-pay-receipt-js" */),
  "component---src-pages-personal-broadband-application-jsx": () => import("./../../../src/pages/personal/broadband/application.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-application-jsx" */),
  "component---src-pages-personal-broadband-business-application-js": () => import("./../../../src/pages/personal/broadband/business-application.js" /* webpackChunkName: "component---src-pages-personal-broadband-business-application-js" */),
  "component---src-pages-personal-broadband-index-jsx": () => import("./../../../src/pages/personal/broadband/index.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-index-jsx" */),
  "component---src-pages-personal-broadband-offers-bundles-js": () => import("./../../../src/pages/personal/broadband/offers/bundles.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-bundles-js" */),
  "component---src-pages-personal-broadband-offers-flexi-offers-js": () => import("./../../../src/pages/personal/broadband/offers/flexi-offers.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-flexi-offers-js" */),
  "component---src-pages-personal-broadband-offers-one-family-js": () => import("./../../../src/pages/personal/broadband/offers/one-family.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-one-family-js" */),
  "component---src-pages-personal-broadband-offers-special-offers-and-promotions-js": () => import("./../../../src/pages/personal/broadband/offers/special-offers-and-promotions.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-special-offers-and-promotions-js" */),
  "component---src-pages-personal-broadband-offers-standard-bundles-js": () => import("./../../../src/pages/personal/broadband/offers/standard-bundles.js" /* webpackChunkName: "component---src-pages-personal-broadband-offers-standard-bundles-js" */),
  "component---src-pages-personal-broadband-promos-2-moorch-family-offer-js": () => import("./../../../src/pages/personal/broadband/promos/2-moorch-family-offer.js" /* webpackChunkName: "component---src-pages-personal-broadband-promos-2-moorch-family-offer-js" */),
  "component---src-pages-personal-broadband-relocation-jsx": () => import("./../../../src/pages/personal/broadband/relocation.jsx" /* webpackChunkName: "component---src-pages-personal-broadband-relocation-jsx" */),
  "component---src-pages-personal-mobile-index-jsx": () => import("./../../../src/pages/personal/mobile/index.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-index-jsx" */),
  "component---src-pages-personal-mobile-offers-bundles-js": () => import("./../../../src/pages/personal/mobile/offers/bundles.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-bundles-js" */),
  "component---src-pages-personal-mobile-offers-international-offers-jsx": () => import("./../../../src/pages/personal/mobile/offers/international-offers.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-offers-international-offers-jsx" */),
  "component---src-pages-personal-mobile-offers-roaming-bundles-jsx": () => import("./../../../src/pages/personal/mobile/offers/roaming-bundles.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-offers-roaming-bundles-jsx" */),
  "component---src-pages-personal-mobile-offers-tariffs-js": () => import("./../../../src/pages/personal/mobile/offers/tariffs.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-tariffs-js" */),
  "component---src-pages-personal-mobile-offers-telecel-black-apply-js": () => import("./../../../src/pages/personal/mobile/offers/telecel-black/apply.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-telecel-black-apply-js" */),
  "component---src-pages-personal-mobile-offers-telecel-black-index-js": () => import("./../../../src/pages/personal/mobile/offers/telecel-black/index.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-telecel-black-index-js" */),
  "component---src-pages-personal-mobile-offers-top-up-js": () => import("./../../../src/pages/personal/mobile/offers/top-up.js" /* webpackChunkName: "component---src-pages-personal-mobile-offers-top-up-js" */),
  "component---src-pages-personal-mobile-promos-more-money-promo-js": () => import("./../../../src/pages/personal/mobile/promos/more-money-promo.js" /* webpackChunkName: "component---src-pages-personal-mobile-promos-more-money-promo-js" */),
  "component---src-pages-personal-mobile-promos-tgma-jsx": () => import("./../../../src/pages/personal/mobile/promos/tgma.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-promos-tgma-jsx" */),
  "component---src-pages-personal-mobile-shop-agent-locator-jsx": () => import("./../../../src/pages/personal/mobile/shop/agent-locator.jsx" /* webpackChunkName: "component---src-pages-personal-mobile-shop-agent-locator-jsx" */),
  "component---src-pages-personal-mobile-shop-connected-devices-js": () => import("./../../../src/pages/personal/mobile/shop/connected-devices.js" /* webpackChunkName: "component---src-pages-personal-mobile-shop-connected-devices-js" */),
  "component---src-pages-personal-mobile-shop-devices-js": () => import("./../../../src/pages/personal/mobile/shop/devices.js" /* webpackChunkName: "component---src-pages-personal-mobile-shop-devices-js" */),
  "component---src-pages-personal-telecel-cash-airtime-purchase-js": () => import("./../../../src/pages/personal/telecel-cash/airtime-purchase.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-airtime-purchase-js" */),
  "component---src-pages-personal-telecel-cash-atm-withdrawal-js": () => import("./../../../src/pages/personal/telecel-cash/atm-withdrawal.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-atm-withdrawal-js" */),
  "component---src-pages-personal-telecel-cash-bank-services-js": () => import("./../../../src/pages/personal/telecel-cash/bank-services.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-bank-services-js" */),
  "component---src-pages-personal-telecel-cash-index-jsx": () => import("./../../../src/pages/personal/telecel-cash/index.jsx" /* webpackChunkName: "component---src-pages-personal-telecel-cash-index-jsx" */),
  "component---src-pages-personal-telecel-cash-international-money-transfer-js": () => import("./../../../src/pages/personal/telecel-cash/international-money-transfer.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-international-money-transfer-js" */),
  "component---src-pages-personal-telecel-cash-mebanbo-funeral-insurance-js": () => import("./../../../src/pages/personal/telecel-cash/mebanbo-funeral-insurance.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-mebanbo-funeral-insurance-js" */),
  "component---src-pages-personal-telecel-cash-merchant-registration-js": () => import("./../../../src/pages/personal/telecel-cash/merchant-registration.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-merchant-registration-js" */),
  "component---src-pages-personal-telecel-cash-mi-future-js": () => import("./../../../src/pages/personal/telecel-cash/miFuture.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-mi-future-js" */),
  "component---src-pages-personal-telecel-cash-mobile-money-interoperability-js": () => import("./../../../src/pages/personal/telecel-cash/mobile-money-interoperability.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-mobile-money-interoperability-js" */),
  "component---src-pages-personal-telecel-cash-one-4-sure-js": () => import("./../../../src/pages/personal/telecel-cash/one-4-sure.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-one-4-sure-js" */),
  "component---src-pages-personal-telecel-cash-pos-payments-js": () => import("./../../../src/pages/personal/telecel-cash/pos-payments.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-pos-payments-js" */),
  "component---src-pages-personal-telecel-cash-ready-loan-js": () => import("./../../../src/pages/personal/telecel-cash/ready-loan.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-ready-loan-js" */),
  "component---src-pages-personal-telecel-cash-safenet-insurance-js": () => import("./../../../src/pages/personal/telecel-cash/safenet-insurance.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-safenet-insurance-js" */),
  "component---src-pages-personal-telecel-cash-telecel-cash-overdraft-js": () => import("./../../../src/pages/personal/telecel-cash/telecel-cash-overdraft.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-telecel-cash-overdraft-js" */),
  "component---src-pages-personal-telecel-cash-travel-companion-js": () => import("./../../../src/pages/personal/telecel-cash/travel-companion.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-travel-companion-js" */),
  "component---src-pages-personal-telecel-cash-tv-subscriptions-js": () => import("./../../../src/pages/personal/telecel-cash/tv-subscriptions.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-tv-subscriptions-js" */),
  "component---src-pages-personal-telecel-cash-utility-js": () => import("./../../../src/pages/personal/telecel-cash/utility.js" /* webpackChunkName: "component---src-pages-personal-telecel-cash-utility-js" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-tests-js": () => import("./../../../src/pages/tests.js" /* webpackChunkName: "component---src-pages-tests-js" */)
}

